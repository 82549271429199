import { Component, OnInit, ɵConsole, AfterViewInit } from "@angular/core";
import { ActivatedRoute, Router,RouterStateSnapshot, NavigationEnd,PRIMARY_OUTLET} from "@angular/router";
import { filter } from "rxjs/operators";
import { map } from "rxjs/internal/operators";

@Component({
  selector: "app-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.css"]
})
export class BreadcrumbComponent implements OnInit {
  ClientName: string;
  breadcrumbs: any = [];

  constructor(private  route: ActivatedRoute, private router:Router) {
    router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    ).subscribe(e => {
      this.breadcrumbs = [];
      let child = this.route.firstChild;
   //  console.log(e.id, e.url);
     // console.log(child.snapshot.data['breadcrumb']);

      let url = this.router.routerState.snapshot.url;
      let label = child.snapshot.data['breadcrumb'];

      this.breadcrumbs.push({
        url:  url,
        label: label,
      });

      return null;
    });
  }

  ngOnInit() {
    this.ClientName = sessionStorage.getItem('AppClientName');
  }
}
