import { Injectable } from '@angular/core';
import { INotification } from './INotification';
import { CPushMsgActivityReqBody } from './CPushMsgActivityReqBody';
import { IPushMsgActivityResponse } from './IPushMsgActivityResponse';
import { Http,  Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators/map';
import { environment } from '../../environments/environment';

@Injectable()
export class NotificationServiceComponent {
  TargetId: string;
  // tslint:disable-next-line: deprecation
  constructor(private http: Http, private httpClient: HttpClient) { }

  getData(): Promise<INotification[]> {
      this.TargetId = sessionStorage.getItem('TargetId');

      return this.http.get(environment.apiEndpoint + '/Dashboard/GetNotificationDetails/' + this.TargetId)
      .toPromise()
      .then(res => res.json())
      .catch(err => {
          return Promise.reject(err.json().error  || 'Server error');
      });

  }

  UpdatePushNotification(CampaignIds: string[], ActivityCode: string): Promise<IPushMsgActivityResponse> {
    let CampaignIdString: string = CampaignIds.join(',');
    this.TargetId = sessionStorage.getItem('TargetId');
    const RequestBody: CPushMsgActivityReqBody = new CPushMsgActivityReqBody('', '', this.TargetId, CampaignIdString, ActivityCode);

    return this.http.post(environment.apiEndpoint + '/Dashboard/PushMsgActivity', RequestBody)
    .toPromise()
    .then(res => res.json())
    .catch(err => {
        return Promise.reject(err.json().error  || 'Server error');
    });

  }

}
