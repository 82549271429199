import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-leftinfo',
  templateUrl: './leftinfo.component.html',
  styleUrls: ['./leftinfo.component.css']
})
export class LeftinfoComponent implements OnInit {
  PlayerId: string;
  Tire: string;
  TierPoint: string;
  UserPoints: string;
  ClientName: string;
  constructor() { }

  ngOnInit() {
    this.Tire = sessionStorage.getItem('ClientTierName');
    this.TierPoint = sessionStorage.getItem('ClientTierPoint');
    this.PlayerId = sessionStorage.getItem('PlayerId');
    this.UserPoints = sessionStorage.getItem('UserPoints');
    this.ClientName = sessionStorage.getItem('AppClientName');
  }

}
