import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ISignupResponseDetails } from './ISignupResponseDetails';
import { SignupServiceComponent } from './signup.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
  providers: [SignupServiceComponent]
})
export class SignupComponent implements OnInit {
  
  signupForm: FormGroup;
  submitted = false;
  playerDOB: string;
  signupError: string;
  ResponseData: ISignupResponseDetails;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    private appService: SignupServiceComponent
  ) { }

  ngOnInit() {
    this.signupForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.playerDOB='';
  }

  // convenience getter for easy access to form fields
  get f() { return this.signupForm.controls; }

  GetDateRange(event: any) {
    this.playerDOB = this.datePipe.transform(new Date(event.target.value),"MM-dd-yyyy");    
  }

  onSubmit() {
    console.log('Username:' + this.f.username.value);
    console.log('Password:' + this.f.password.value);    
    console.log('DOB:' + this.playerDOB);
    this.signupError = '';

    this.appService.signupUser(this.f.username.value, this.f.password.value,this.playerDOB)
    .then(svcData => {
        this.ResponseData = svcData;
        console.log('In Promise:' + this.ResponseData.Success);

        if ((String(this.ResponseData.Success) === 'True') || (String(this.ResponseData.Success) === 'true')) {
          console.log('Signup successful!!');
          this.router.navigate(['/login']);
        }

        if ((String(this.ResponseData.Success) === 'False') || (String(this.ResponseData.Success) === 'false')) {
          console.log('Signup unsuccessful!!');
          this.signupError = this.ResponseData.Message;
        }

    });
  }
}

