import { Injectable } from '@angular/core';
import { ISaveProfileResponse } from './ISaveProfileResponse';
import { CSaveProfileReqBody } from './CSaveProfileReqBody';
import { IUSAState } from './IUSAState';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators/map';
import { environment } from '../../environments/environment';

@Injectable()
export class ProfileServiceComponent {

  // tslint:disable-next-line: deprecation
  constructor(private http: Http, private httpClient: HttpClient) { }

  getUSAStates(): Observable<IUSAState[]> {
      return this.http.get(environment.apiEndpoint + '/Dashboard/GetUSAStates')
      .pipe(map((response: Response) => response.json() as IUSAState[]));
  }

  SaveTargetProfile(firstName: string, lastName: string, telephoneMobile: string,
                    emailAddress: string, addressLine1: string, addressLine2: string,
                    city: string, stateProvince: string, zip: string): Promise<ISaveProfileResponse> {
    const targetId: number = Number(sessionStorage.getItem('TargetId'));
    const RequestBody: CSaveProfileReqBody = new CSaveProfileReqBody(0, targetId, firstName, lastName, telephoneMobile, emailAddress,
                                                                      addressLine1, addressLine2, city, stateProvince, zip);

    // return this.httpClient.post(environment.apiEndpoint + '/Dashboard/PushMsgActivity', RequestBody)
    // .pipe(map((response: Response) => response.json() as ISaveProfileResponse));
    /*const headers = new Headers(
      {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*'
      }
    );

    const options = new RequestOptions({ headers: headers });*/
    return this.http.post(environment.apiEndpoint + '/Dashboard/SaveTargetProfile', RequestBody)
    .toPromise()
    .then(res => res.json())
    .catch(err => {
        return Promise.reject(err.json().error  || 'Server error');
    });
  }

}
