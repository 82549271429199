import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
declare var $:JQueryStatic;

@Component({
  selector: 'app-leftcount',
  templateUrl: './leftcount.component.html',
  styleUrls: ['./leftcount.component.css']
})
export class LeftcountComponent implements OnInit {
  TierPoint: string;
  ClientName: string;
  constructor() {
   }

  ngOnInit() {
    // this.data.UserTierPoint.subscribe(message => this.UserTierPoint = message);
    this.ClientName = sessionStorage.getItem('AppClientName');
    //this.TierPoint = sessionStorage.getItem('ClientTierPoint');
    this.TierPoint = sessionStorage.getItem('UserPoints');
  }
  ngAfterViewInit() {
      $(document).ready(function():void
      {

        // tier score - home
        var $grades = $('.grade');

      $grades.each(function() {
        var $grade = $(this);
        var $foreground = $grade.find('.grade__icon__foreground');
        var scorePercentage = $grade.data('grade-score') * 10;
        var foregroundCircumference = 2 * Math.PI * parseInt($foreground.attr('r'));
        var foregroundDashOffset = foregroundCircumference - ((foregroundCircumference * scorePercentage) / 100);

        $foreground.css('stroke-dasharray', foregroundCircumference);
        $foreground.css('stroke-dashoffset', foregroundCircumference);

        setTimeout(function() {
          $grade.addClass('animate');
          $foreground.css('stroke-dashoffset', foregroundDashOffset);
        }, 200);
      });
    });
  }
}
