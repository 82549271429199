import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.css']
})

export class NotFoundComponent implements OnInit {

  ngOnInit() {

  }

}
