import { Injectable } from '@angular/core';
import { ISignupResponseDetails } from './ISignupResponseDetails';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators/map';
import { environment } from '../../environments/environment';

@Injectable()
export class SignupServiceComponent {

  // tslint:disable-next-line: deprecation
  constructor(private http: Http) { }

  signupUser(userName: string, password: string, dateofbirth: string): Promise<ISignupResponseDetails> {
      console.log(environment.apiEndpoint + '/Dashboard/SignUpUser/' + userName + '/' + password + '/' + dateofbirth);      
      
      return this.http.get(environment.apiEndpoint + '/Dashboard/SignUpUser/' + userName + '/' + password + '/' + dateofbirth)
            .toPromise()
            .then(res => res.json())
            .catch(err => {
                return Promise.reject(err.json().error  || 'Server error');
            });

  }  
}