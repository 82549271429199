import { Component, OnInit } from '@angular/core';
import { Router }  from "@angular/router";
import { ProfileinfoServiceComponent } from './profileinfo.service';
import { ISignOutResponse } from './ISignOutResponse';


declare var $:JQueryStatic;
@Component({
  selector: 'app-profileinfo',
  templateUrl: './profileinfo.component.html',
  styleUrls: ['./profileinfo.component.css'],
  providers: [ProfileinfoServiceComponent]
})
export class ProfileinfoComponent implements OnInit {
  UserName: string;
  UserData: ISignOutResponse;
  constructor(private router : Router, private appService: ProfileinfoServiceComponent) { }

  ngOnInit() {
    // this.data.UserTierPoint.subscribe(message => this.UserTierPoint = message);
    this.UserName = sessionStorage.getItem('AppUserName');
  }

  logout()  {
    console.log('At Logout event...');
    this.appService.signOutUser()
    .then(svcData => {
        this.UserData = svcData;
        console.log('In Promise:' + this.UserData.Success);

        if ((String(this.UserData.Success) === 'True') || (String(this.UserData.Success) === 'true')) {          
          console.log('logged Out');
          this.router.navigate(['/login']);
        }
    });
    
    event.preventDefault();
    sessionStorage.removeItem('ClientTierPoint');
    sessionStorage.removeItem('AppUserName');
    sessionStorage.removeItem('ClientTierName');
    sessionStorage.removeItem('AppClientName');
    sessionStorage.removeItem('CreditToNextTier');
    sessionStorage.removeItem('UserPoints');
    sessionStorage.removeItem('PlayerId');
    sessionStorage.removeItem('TargetId');
    
  }

}
