import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProfileServiceComponent } from './profile-service';
import { IUSAState } from './IUSAState';
import { ISaveProfileResponse } from './ISaveProfileResponse';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
  providers: [ProfileServiceComponent]
})
export class ProfileComponent implements OnInit {
  AppUserLoginname: string;
  AppUserLoginPassword: string;
  AppUserFirstName: string;
  AppUserLastName: string;
  AppUserPhoneMain: string;
  AppUserPhoneMobile: string;
  AppUserEmailAddress: string;
  AppUserAddress1: string;
  AppUserAddress2: string;
  AppUserCity: string;
  AppUserState: string;
  AppUserZipcode: string;
  AppUserCountry: string;
  AppUsername: string;
  USAStates: IUSAState[];
  state;
  profileForm: FormGroup;
  loading = false;
  submitted = false;
  saveResponse: ISaveProfileResponse;
  statusMessage = '';
  danger = false;
  warning = false;
  success = false;
  constructor(public router: Router, private appService: ProfileServiceComponent, private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.profileForm = this.formBuilder.group({
      firstname: ['', ''],
      lastname: ['', ''],
      phonemobile: ['', Validators.required],
      emailaddress: ['', Validators.required],
      address1: ['', Validators.required],
      address2: ['', ''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip: ['', Validators.required]
    });

    this.AppUserLoginname = sessionStorage.getItem('AppUserLoginname');
    this.AppUserLoginPassword = sessionStorage.getItem('AppUserLoginPassword');
    this.AppUserFirstName = sessionStorage.getItem('AppUserFirstName');
    this.AppUserLastName = sessionStorage.getItem('AppUserLastName');
    this.AppUserPhoneMain = sessionStorage.getItem('AppUserPhoneMain');
    this.AppUserPhoneMobile = sessionStorage.getItem('AppUserPhoneMobile');
    this.AppUserEmailAddress = sessionStorage.getItem('AppUserEmailAddress');
    this.AppUserAddress1 = sessionStorage.getItem('AppUserAddress1');
    this.AppUserAddress2 = sessionStorage.getItem('AppUserAddress2');
    this.AppUserCity = sessionStorage.getItem('AppUserCity');
    this.state = this.AppUserState = sessionStorage.getItem('AppUserState');
    this.AppUserZipcode = sessionStorage.getItem('AppUserZipcode');
    this.AppUserCountry = sessionStorage.getItem('AppUserCountry');
    this.AppUsername = sessionStorage.getItem('AppUserName');

    this.appService.getUSAStates()
      .subscribe((svcData) => this.USAStates = svcData);

  }

  // convenience getter for easy access to form fields
  get f() { return this.profileForm.controls; }

  onSubmit() {
    this.submitted = true;
    $('.alert').removeAttr('style');
    this.danger = false;
    this.warning = false;
    this.success = false;
    /*
    console.log('Firstname:' + this.f.firstname.value);
    console.log('Lastname:' + this.f.lastname.value);
    console.log('Mobile:' + this.f.phonemobile.value);
    console.log('Email:' + this.f.emailaddress.value);
    console.log('Address1:' + this.f.address1.value);
    console.log('Address2:' + this.f.address2.value);
    console.log('City:' + this.f.city.value);
    console.log('State:' + this.f.state.value);
    console.log('Zip:' + this.f.zip.value);
    */
    // stop here if form is invalid
    if (this.profileForm.invalid) {
      return;
    }
    console.log('Form is valid..');


    this.appService.SaveTargetProfile(this.f.firstname.value, this.f.lastname.value, this.f.phonemobile.value,
                                      this.f.emailaddress.value, this.f.address1.value, this.f.address2.value,
                                      this.f.city.value, this.f.state.value, this.f.zip.value)
    .then(svcData => {
        this.saveResponse = svcData;
        console.log('In Promise:' + this.saveResponse.Success);
        if (this.saveResponse.Success) {
          this.statusMessage = 'Profile updated successfully...';
          this.danger = false;
          this.warning = false;
          this.success = true;
          sessionStorage.setItem('AppUserPhoneMobile', this.f.phonemobile.value);
          sessionStorage.setItem('AppUserEmailAddress', this.f.emailaddress.value);
          sessionStorage.setItem('AppUserAddress1', this.f.address1.value);
          sessionStorage.setItem('AppUserAddress2', this.f.address2.value);
          sessionStorage.setItem('AppUserCity', this.f.city.value);
          sessionStorage.setItem('AppUserState', this.f.state.value);
          sessionStorage.setItem('AppUserZipcode', this.f.zip.value);
          console.log('Success Response:' + this.saveResponse.msg);
        } else {
          this.statusMessage = 'Profile could not be updated...';
          this.danger = true;
          this.warning = false;
          this.success = false;
          console.log('Failure Response:' + this.saveResponse.msg);
        }
    });
    $('.alert').fadeOut(5000);
  }
}
