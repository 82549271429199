import { Injectable } from '@angular/core';
import { ISignOutResponse } from './ISignOutResponse';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators/map';
import { environment } from '../../environments/environment';

@Injectable()
export class ProfileinfoServiceComponent {

  // tslint:disable-next-line: deprecation
  constructor(private http: Http) { }

  signOutUser(): Promise<ISignOutResponse> {
      console.log(environment.apiEndpoint + '/Dashboard/SignOutUser/' + sessionStorage.getItem('AccessToken') + '/' + sessionStorage.getItem('SessionId') + '/' + sessionStorage.getItem('PlayerId') );
      //return this.http.get(environment.apiEndpoint + '/Dashboard/AuthenticateUser/' + userName + '/' + password)
      //.pipe(map((response: Response) => {
      //  console.log('In Service:' + new Date() + '-' + response.json());
      //  return response.json() as IAppUserDetails;
      //}));
      
      return this.http.get(environment.apiEndpoint + '/Dashboard/SignOutUser/' + sessionStorage.getItem('AccessToken') + '/' + sessionStorage.getItem('SessionId') + '/' + sessionStorage.getItem('PlayerId') )
            .toPromise()
            .then(res => res.json())
            .catch(err => {
                return Promise.reject(err.json().error  || 'Server error');
            });


  }

  public get currentUserValue(): string {
    //return sessionStorage.getItem('AppUserName');

    return null;
  }
}