import { Component, OnInit } from '@angular/core';
//import { IOfferDetails } from './IOfferDetails';
import { IPromotionDetails } from './IPromotionDetails';
import { SliderServiceComponent } from './slider-service';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css'],
  providers: [SliderServiceComponent]
})
export class SliderComponent implements OnInit {
  //OfferData: IOfferDetails[];
  PromotionData: IPromotionDetails[];
  ClientName: string;
  constructor(private appService: SliderServiceComponent) { }

  ngOnInit() {
    //this.appService.getData()
      //.subscribe((svcData) => this.OfferData = svcData);

      this.appService.getPromotionData()
      .subscribe((svcData1) => this.PromotionData = svcData1);

    this.ClientName = sessionStorage.getItem('AppClientName');
  }

}
