import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {
  ClientName: string;
  TierName: string;
  NextTierName: string;
  UserName: string;
  CreditToNextTier: string;

  TierPointVal: number;
  CreditToNextTierVal: number;
  constructor() { }

  ngOnInit() {
    // this.data.UserTierPoint.subscribe(message => this.UserTierPoint = message);
    this.UserName = sessionStorage.getItem('AppUserName');
    this.ClientName = sessionStorage.getItem('AppClientName');
    this.TierName = sessionStorage.getItem('ClientTierName');
    //this.NextTierName = sessionStorage.getItem('NextTierLevel');
    this.NextTierName = sessionStorage.getItem('ClientTierName');
    this.CreditToNextTier = sessionStorage.getItem('CreditToNextTier');

    //Basin: 0 - 4,999
    //Mesa: 5,000 – 24,999
    //Timberline: 25,000 – 74,999
    //Pinnacle: 75,000+

    this.TierPointVal = 0;
    this.CreditToNextTierVal = 0;
    if(sessionStorage.getItem('ClientTierPoint') != null)
    {
      this.TierPointVal = Number(sessionStorage.getItem('ClientTierPoint'));
    }

    //Basin: 0 - 4,999
    //if(this.TierName.toUpperCase() == 'BASIN')
    //{
    //  if(this.TierPointVal >= 0 && this.TierPointVal <= 4999)
    //  {
     //   this.CreditToNextTierVal = (5000 -  this.TierPointVal);
     //   this.NextTierName = 'BASIN';
    //  }
    //}

    //Mesa: 5,000 – 24,999
    //if(this.TierName.toUpperCase() == 'MESA')
    //{
    //  if(this.TierPointVal >= 5000 && this.TierPointVal <= 24999)
    //  {
    //    this.CreditToNextTierVal = (25000 -  this.TierPointVal);
    //    this.NextTierName = 'MESA';
    //  }
    //}

    //Timberline: 25,000 – 74,999
    //if(this.TierName.toUpperCase() == 'TIMBERLINE')
    //{
     // if(this.TierPointVal >= 25000 && this.TierPointVal <= 74999)
    //  {
    //    this.CreditToNextTierVal = (75000 -  this.TierPointVal);
    //    this.NextTierName = 'TIMBERLINE'
     // }
    //}

    //Pinnacle: 75,000+
    //if(this.TierName.toUpperCase() == 'PINNACLE')
    //{
     // if(this.TierPointVal >= 75000)
     // {
     //   this.CreditToNextTierVal = 0;
     //   this.NextTierName = 'PINNACLE'
     // }
    //}
    
    //this.CreditToNextTier = Number(this.CreditToNextTierVal).toLocaleString('en-GB');
  }

}
