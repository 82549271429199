import { Component, OnInit, DoCheck } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { INotification } from './INotification';
import { NotificationServiceComponent } from './notification-service';
import * as $ from 'jquery';
import { stringify } from 'querystring';
import { IPushMsgActivityResponse } from './IPushMsgActivityResponse';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css'],
  providers: [NotificationServiceComponent]
})
export class NotificationComponent implements OnInit, DoCheck {
  NotificationData: INotification[];
  PushMsgResponseData: IPushMsgActivityResponse;
  UserName: string;
  selectedChkValue = [];
  allChkValue = [];
  statusMessage = '';
  danger = false;
  warning = false;
  success = false;
  constructor(private appService: NotificationServiceComponent, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.spinner.show();

    this.UserName = sessionStorage.getItem('AppUserName');

    this.appService.getData()
      .then((svcData) => this.NotificationData = svcData);

    console.log('All Items Length:' + this.allChkValue.length);
    console.log('Selected Items Length:' + this.selectedChkValue.length);

    setTimeout(() => {
        /** spinner ends after 3 seconds */
        this.spinner.hide();
      }, 3000);
  }

  ngDoCheck() {
    this.allChkValue.length = 0;

    let index: number = 0;
    if (this.NotificationData) {
      while (index < this.NotificationData.length) {
      this.allChkValue.push(this.NotificationData[index].NotificationId.toString());
      index ++;
      }
    }

    console.log('At Do Check -> All Items Length:' + this.allChkValue.length);
    console.log('At Do Check -> Selected Items Length:' + this.selectedChkValue.length);
  }

  MarkRead() {
    this.statusMessage = '';
    this.appService.UpdatePushNotification(this.allChkValue, '2')
      .then((svcData) => this.PushMsgResponseData = svcData);

    this.allChkValue.length = 0;
    this.selectedChkValue.length = 0;

    this.ngOnInit();
  }

  DeleteSelected() {
    this.statusMessage = '';
    $('.alert').removeAttr('style');
    this.danger = false;
    this.warning = false;
    this.success = false;
    console.log('Before operation - count:' + this.NotificationData.length);
    this.appService.UpdatePushNotification(this.selectedChkValue, '3')
    .then(svcData => {
      this.PushMsgResponseData = svcData;
      if(this.selectedChkValue.length > 0) {
        this.danger = true;
        this.statusMessage = 'Selected Notification(s) deleted successfully...';
      } else {
        this.danger = false;
        this.statusMessage = '';
      }
      //this.danger = true;
      this.warning = false;
      this.success = false;

      this.appService.getData()
      .then((noteData) => {
        this.NotificationData = noteData;
        console.log('After operation - count:' + this.NotificationData.length);
      });
      $('.alert').fadeOut(5000);
    });


    this.allChkValue.length = 0;
    this.selectedChkValue.length = 0;

    //  this.allChkValue.slice(0, this.allChkValue.length);
    //  this.selectedChkValue.slice(0, this.selectedChkValue.length);
    this.ngOnInit();
  }

  chkChange(e, selNotId) {
    this.statusMessage = '';
    console.log(e.target.checked);
    console.log(selNotId);
    console.log('Before Push:' + this.selectedChkValue);
    if (e.target.checked) {
      if ((this.selectedChkValue.length > 0) && (this.selectedChkValue.indexOf(selNotId) >= 0)) {
        this.selectedChkValue.splice(this.selectedChkValue.indexOf(selNotId), 1);
      }

      this.selectedChkValue.push(selNotId);
    } else {
     this.selectedChkValue.splice(this.selectedChkValue.indexOf(selNotId), 1);
      $('#chk_All').prop('checked', false); 
     //console.log($('#chk_All').prop('checked'));

    }
    console.log('After Push:' + this.selectedChkValue);
  }

  SelectAll(event) {
    this.statusMessage = '';
    const c = event.target.checked;
    $(':checkbox').prop('checked', c);

    this.selectedChkValue.length = 0;

    console.log('At Select All -> Checkbox Checked Status:' + c);

    let index: number = 0;
    if (this.allChkValue.length > 0 && c == true) {
      while (index < this.allChkValue.length) {
      this.selectedChkValue.push(this.allChkValue[index].toString());
      index ++;
      }
    }

    console.log('At Select All -> All Items Length:' + this.allChkValue.length);
    console.log('At Select All -> Selected Items Length:' + this.selectedChkValue.length);

  }
}
