import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule, MatDatepickerModule, MatFormFieldModule, MatInputModule, MatNativeDateModule } from '@angular/material';
import { SatDatepickerModule } from '../../saturn-datepicker/src/datepicker';
import { SatNativeDateModule } from '../../saturn-datepicker/src/datetime';
import {DatePipe} from '@angular/common';


import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { loggedinComponent } from './loggedin/loggedin.component';
import { SignupComponent } from './signup/signup.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LeftmenuComponent } from './leftmenu/leftmenu.component';
import { LeftinfoComponent } from './leftinfo/leftinfo.component';
import { LeftcontactComponent } from './leftcontact/leftcontact.component';
import { BannerComponent } from './dashboard/banner/banner.component';
import { LeftcountComponent } from './dashboard/leftcount/leftcount.component';
import { SliderComponent } from './dashboard/slider/slider.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { SearchComponent } from './search/search.component';
import { ProfileinfoComponent } from './profileinfo/profileinfo.component';
import { OfferpromoComponent } from './offerpromo/offerpromo.component';
import { NotificationComponent } from './notification/notification.component';
import { ProfileComponent } from './profile/profile.component';
import { NotFoundComponent } from './notfound/notfound.component';

import { AuthGuard } from './guard/auth.guard';




const appRoutes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'home', component: loggedinComponent, canActivate: [AuthGuard],

      children: [
                {
                  path: '',
                  redirectTo : '/home/(sidebar:dashboard)',
                  pathMatch: 'full'
              },
              {
                path: 'dashboard',
                outlet: 'sidebar',
                component: DashboardComponent,
                data: {
                  breadcrumb: 'Rewards Dashboard'
                }
            },
            {
                path: 'offerpromo',
                outlet: 'sidebar',
                component: OfferpromoComponent,
                data: {
                  breadcrumb: 'Offers & Promos'
                }
            },

            {
                path: 'notification',
                outlet: 'sidebar',
                component: NotificationComponent,
                data: {
                  breadcrumb: 'Notifications'
                }
            },
            {
                path: 'profile',
                outlet: 'sidebar',
                component: ProfileComponent,
                data: {
                  breadcrumb: 'Profile'
                }
            },
    ]
  },

 // { path: '', component: DashboardComponent, outlet: "dashboard" },
  //{ path: 'home', component: OfferpromoComponent, outlet: "offerpromo" },
  //{ path: 'home', component: NotificationComponent, outlet: "notification" },
  //{ path: 'home', component: ProfileComponent, outlet: "profile" },


  { path: '',    redirectTo: '/login',   pathMatch: 'full'},
  { path: '**', redirectTo : '/login', pathMatch: 'full'},
 

];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    loggedinComponent,
    DashboardComponent,
    LeftmenuComponent,
    LeftinfoComponent,
    LeftcontactComponent,
    BannerComponent,
    LeftcountComponent,
    SliderComponent,
    BreadcrumbComponent,
    SearchComponent,
    ProfileinfoComponent,
    OfferpromoComponent,
    NotificationComponent,
    ProfileComponent,
    NotFoundComponent,
    SignupComponent
  ],

  imports: [
    HttpModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule, MatFormFieldModule, MatInputModule,
    MatButtonModule, SatDatepickerModule, SatNativeDateModule,
    NgxSpinnerModule,
    RouterModule.forRoot(appRoutes)
  ],

  providers: [
    DatePipe,
    AuthGuard,
  ],

  bootstrap: [AppComponent]

})
export class AppModule { }
